import React from "react";

import enamad from "../../images/enamad-logo.png";
import etehadiye from "../../images/etehadiye.png";

import styles from "./Footer.module.css";

import { Link } from 'react-router-dom/cjs/react-router-dom.min';

const Footer = () => {
  const style = {
    // backgroundColor: "#26246e",
    textAlign: "center",
    padding: "1.5rem",
    margin: "0px 0px",
    borderTop: "2px solid #fff",
  };

  const scrollToTop = () => {
    window.scrollTo(0, 0)
  }

  return (
    <div className={styles.container}>
      <div className={styles.section}>
        <div className={styles.info}>
          {/* <h3>سِیرو</h3> */}
          <h3>سیستم هوشمند سرویس مدارس</h3>
          <p>
            سِیرو به عنوان اولین سیستم هوشمند سرویس مدارس شهر تهران ، با ارائه
            اپلیکیشن مختص به والدین و رانندگان با امکاناتی فوق العاده همچون
            سیستم پایش لحظه ای وضعیت سرویس این امکان را به والدین میدهد تا در
            لحظه از وضعیت سرویس و فرزند خود مطلع شوند.
          </p>
        </div>

        <div className={styles.links}>
          
          <div>
            <h3>سِیرو</h3>
            <ul>
              <li onClick={scrollToTop}><Link to="/about-us">درباره ما</Link></li>
              <li onClick={scrollToTop}><Link to="/contact-us">تماس با ما</Link></li>
              <li onClick={scrollToTop}><Link to="/terms-policy">قوانین و مقررات</Link></li>
            </ul>
          </div>

          <div>
            <h3>رانندگان</h3>
            <ul>
              <li onClick={scrollToTop}><Link to="/driver">اپلیکیشن رانندگان</Link></li>
            </ul>
          </div>

          <div>
            <h3>کاربران</h3>
            <ul>
              <li onClick={scrollToTop}><Link to="/download">اپلیکیشن کاربران</Link></li>
            </ul>
          </div>

        </div>

        <div className={styles.break}>

        </div>

        <div className={styles.logo}>
          <a href="/">
            <img src={enamad} alt="enamad" />
          </a>
          <div className={styles.logo_gap}></div>
          <a href="/" className={styles.etehadiye}>
            <img src={etehadiye} alt="etehadiye" />
            <p>در انتظار پروانه کسب</p>
          </a>
        </div>
      </div>
      <div>
        <p style={style}>
          تمام حقوق مادی و معنوی این وبسایت متعلق به سِیرو میباشد.
        </p>
        {/* <p>طراحی شده توسط های لپ</p> */}
      </div>
      <div className={styles.hilapCo}>
        طراحی شده توسط <a href="https://hilap.net" target="_blank\">های لپ</a>
      </div>
    </div>
  );
};

export default Footer;
