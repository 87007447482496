import React from "react";

import styles from "./InfoSection.module.css";

const InfoSection = () => {
  return (
    <div className={styles.container}>
      <div className={styles.card}>
        <h2>اینجا با سِیرو بیشتر آشنا میشین</h2>
        <p>
          سِیرو به عنوان اولین سیستم هوشمند سرویس مدارس ، با ارائه اپلیکیشن مختص
          به والدین این امکان را برای شما فراهم می‌آورد تا بتوانید به راحتی مسیر
          حرکت سرویس خود را در تمام طول مسیر مشاهده نمایید و از زمان رسیدن سرویس
          ، سوار شدن و پیاده شدن دانش آموز خود مطلع شوید. همچنین رانندگان سِیرو
          نیز میتوانند از اپلیکیشن مختص به خود که تمام ویژگی های مورد نیاز آن ها
          را دارا میباشد استفاده کنند. با سرویس هوشمند سیِرو ، یک خودروی اختصاصی
          برای رسیدن به مقصدتان دارید. سیِرو ، راهی سریع ، آسان و امن برای سرویس
          مدارس شهر تهران . شما میتوانید همین الان اپلیکیشن متناسب با کاربری خود
          را از بخش بعدی دانلود کنید.
        </p>
      </div>
    </div>
  );
};

export default InfoSection;
