import React , { useState , useEffect } from 'react';

//CSS
import styles from "./userDownload.module.css";

//MAIN Img
import user_img from "../../images/user-app.png"

//Axios for Api
import axios from 'axios';

import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

const UserDownload = () => {

    const [link , setLink] = useState("");

    const errorHandler = (msg) => toast.error(msg , {
        rtl: true,
        theme: "colored",
    });

    useEffect(() => {
        axios.get(`${process.env.REACT_APP_BASE_API_URL}service/application/parent/`)
            .then(res => {
                const apk = res.data.application.split("/");
                if (apk[apk.length - 1].split(".")[1] === "apk") {
                    setLink(`${process.env.REACT_APP_BASE_API_URL_DOWNLOAD}${res.data.application}`)                   
                }else{
                    errorHandler("در حال حاضر دانلود اپلیکیشن امکان پذیر نمی باشد.");
                }                
            })
            .catch(err => errorHandler("در حال حاضر دانلود اپلیکیشن امکان پذیر نمی باشد."));
    } , [])

    return (
        <div className={styles.container}>
            <div className={styles.section}>
                <div className={styles.download_info}>
                    <h2>دانلود اپلیکیشن کاربران سیِرو برای سیستم‌ عامل اندروید و iOS</h2>
                    <div>
                        <div className={styles.download_info_android}>
                            <h3>نسخه مخصوص کاربران اندروید</h3>
                            <a href={link} download="seyro" className={styles.able}>دریافت مستقیم اپلیکیشن</a>

                            <a href='/download' className={styles.disable}>دریافت از مایکت</a>
                            <a href='/download' className={styles.disable}>دریافت از بازار</a>
                            <a href='/download' className={styles.disable}>دریافت از گوگل پلی</a>
                        </div>
                        <div className={styles.download_info_iphone}>
                            <h3>نسخه مخصوص کاربران آیفون</h3>
                            <a href='/download' className={styles.disable}>دریافت مستقیم (نسخه تحت وب)</a>
                            <a href='/download' className={styles.disable}>دریافت از سیب اپ</a>
                        </div>
                    </div>
                </div>
                <div className={styles.download_img}>
                    <img src={user_img} alt="seyro" />    
                </div>                
            </div>
            <ToastContainer />
        </div>
    );
}
 
export default UserDownload;
