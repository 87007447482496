import React from 'react';

import styles from "./LearnSection.module.css"

const LearnSection = () => {
    return (
        <div className={styles.container}>
            <div className={styles.section}>
                <h3>سِیرو ، بزرگترین سیستم سرویس مدارس کشور</h3>
                <h2>اینجا نحوه استفاده از اپلیکیشن سِیرو رو یاد میگیری</h2>
                <h4>همین الان یاد بگیر</h4>
                <a href='/homepage'>شروع یادگیری</a>
            </div>
        </div>
    );
}
 
export default LearnSection;