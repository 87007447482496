import React, { useState } from 'react';

import { Link } from 'react-router-dom/cjs/react-router-dom';
import logo from "../../images/logo.png"

import Styles from "./Navbar.module.css"

import { AiOutlineClose } from "react-icons/ai";


const Navbar = () => {

    const [open , setOpen] = useState(false);

    const scrollToTop = () => {
        window.scrollTo(0, 0)
    }

    return (
        <div className={Styles.header}>
            <div className={Styles.title}>
                <a href='/'><img src={logo} alt="seyro logo" /></a>
                <a href='/'><h2>سِیرو</h2></a>
            </div>
            <ul className={Styles.menu}>
                <li onClick={scrollToTop}><Link to="/">صفحه اصلی</Link></li>
                <li onClick={scrollToTop}><Link to="/about-us">درباره ما</Link></li>
                <li onClick={scrollToTop}><Link to="/contact-us">تماس با ما</Link></li>
            </ul>
            <div className={Styles.span} onClick={() => setOpen(true)}>
                <span></span>
                <span></span>
                <span></span>
            </div>
            <ul className={`${Styles.hamburger} ${open ? Styles.open : Styles.close}`}>
                <div >
                    <AiOutlineClose onClick={() => setOpen(false)}/>
                </div>
                <li onClick={scrollToTop}><Link to="/">صفحه اصلی</Link></li>
                <li onClick={scrollToTop}><Link to="/about-us">درباره ما</Link></li>
                <li onClick={scrollToTop}><Link to="/contact-us">تماس با ما</Link></li>
            </ul>
        </div>
    );
}
 
export default Navbar;