import './App.css';
import { Route , Switch } from 'react-router-dom';
import Footer from './components/layout/Footer';
import Navbar from './components/layout/Navbar';
import HomePage from './components/HomePage';
import Contact from './components/Contact';
import About from './components/AboutUs';
import ScrollToTop from './components/ScrollToTop/ScrollButton.js';
import DriverDownload from './components/Sub Pages/DriverDownload';
import Temporary from './components/Temporary';
import UserDownload from './components/Sub Pages/UserDownload';
import Terms from './components/Terms/Terms';

function App() {
  return (
    <div className="App">

      <Navbar />
      <Switch>
        <Route path="/about-us" component={About} />
        {/* <Route path="/driv" component={DriverDownload} /> */}
        <Route path="/app-download" component={Temporary} />
        <Route path="/terms-policy" component={Terms} />
        <Route path="/download" component={UserDownload} />
        <Route path="/driver" component={DriverDownload} />
        <Route path="/contact-us" component={Contact} />
        <Route path='/' component={HomePage} />
      </Switch>
      <ScrollToTop />
      <Footer />

    </div>
  );
}

export default App;
