import React from 'react';

import bazzar from "../images/bazaar.png";
import direct_download from "../images/direct-download.png";

import client_app from "../assets/app-client.apk";
import driver_app from "../assets/app-driver.apk";

import styles from "./temporary.module.css";

const Temporary = () => {
    return (
        <div className={styles.container}>
            <div className={styles.section}>
                <div className={styles.download_section}>
                    <h2>دانلود اپلیکیشن کاربران</h2>
                    <div>
                        <a href={client_app}><img src={direct_download} alt="download" /></a>
                        <a href={() => {}}><img className={styles.bazzar} src={bazzar} alt="download"/></a>
                    </div>
                </div>
                <div className={styles.row}></div>
                <div className={styles.download_section}>
                    <h2>دانلود اپلیکیشن رانندگان</h2>
                    <div>
                        <a href={driver_app}><img src={direct_download} alt="download" /></a>
                        <a href={() => {}}><img className={styles.bazzar} src={bazzar} alt="download"/></a>
                    </div>
                </div>
            </div>
        </div>
    );
}
 
export default Temporary;