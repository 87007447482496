import React from "react";

import styles from "./CardsSection.module.css";

const CardsSection = () => {
  return (
    <div className={styles.container}>
      <h2>اینجا با امکانات اپلیکیشن سِیرو آشنا میشی</h2>
      <div className={styles.section}>
        <div className={styles.card}>
          <h3>محیط کاربردی</h3>
          <p>
            سیِرو با محیط کاربری راحت، اپلیکیشن مناسبی برای والدین و رانندگان میباشد 
            تا بتوانند بدون مشکل به رفت و آمد فرزندان شما کمک کنند.
          </p>
        </div>
        <div className={styles.card}>
          <h3>امنیت بالا</h3>
          <p>
            امن بودن فرزندان شما و رانندگان هدف اول ماست. سیِرو با در نظر گرفتن
            نکات امنیتی سعی بر فراهم کردن سفری ایمن و با آرامش است.
          </p>
        </div>
        <div className={styles.card}>
          <h3>مطمئن</h3>
          <p>
            سیِرو با در اختیار داشتن رانندگانی مجرب و فراهم نمودن سیستم پایش
            خودرو در مسیر این امکان را به شما میدهد که در لحظه از وضعیت فرزند
            خود باخبر باشید.
          </p>
        </div>
        <div className={styles.card}>
          <h3>آسان</h3>
          <p>
            با اپلیکیشن سیِرو شما میتوانید تمام امور مربوط به سرویس هوشمند مدرسه
            فرزند خود را بدون هیچگونه پیچیدگی و با سهولت و به آسانی انجام دهید.
          </p>
        </div>
      </div>
    </div>
  );
};

export default CardsSection;
