import React, { useEffect, useState } from 'react';

import styles from "./userRegister.module.css";

import logo from "../../images/banner-map.jpg"
import axios from 'axios';
import { Link } from 'react-router-dom/cjs/react-router-dom.min';

import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

import close from "../../images/close-64.png"

const UserRegister = () => {

    const [phone_number , setPhoneNumber] = useState("");
    let [error , setError] = useState(false);

    const changeHandler = e => {
        setPhoneNumber(e.target.value);
    }

    const Tnotify = () => toast.success("لینک برای شما ارسال شد" , {
        rtl: true,
        theme: "colored",
    });

    // , {
    //     headers: {
    //         "Access-Control-Allow-Origin": "https://localhost:3000",
    //         withCredentials: true,
    //     }
    // }

    const clickHandler = e => {
        e.preventDefault();
        if (error) {
            axios.post(`${process.env.REACT_APP_BASE_API_URL}user/invitation/parent/` ,{
                phone_number : phone_number
            })
                .then(res => {
                    if (res.status === 200) {
                        // console.log(res)
                        setPhoneNumber("");
                        Tnotify();
                    }
                })
                
        }
    }

    const [invest , setInvest] = useState(true);


    useEffect(() => {
        validate(phone_number , setError);
    } , [phone_number])

    const validate = (number , setError) => {
        if (number.length === 11) {
            setError(true)
        }else{
            setError(false)
        }
    }

    const scrollToTop = () => {
        window.scrollTo(0, 0)
    }    

    return (
        <div className={styles.container}>
            <div className={styles.topSection}>
                <div className={styles.form}>
                    <div>
                        {/* <h1>سِیرو</h1> */}
                        <h2>سامانه ی نظارت هوشمند و تخصیص سرویس مدارس</h2>
                        {/* <h3>تجربه سرویس راحت تر ، سریع تر و به صرفه تر با اپلیکیشن سیِرو</h3> */}
                        <p>جهت دریافت لینک های مربوط به دانلود اپلیکیشن شماره همراه خود را در باکس زیر وارد نمایید</p>
                        <form className={styles.banner_form} onSubmit={clickHandler}>
                            <input type="number" name='phone_number' value={phone_number} onChange={changeHandler} className={error ? styles.green : styles.red} placeholder="09123456789"/>
                            <button>دریافت لینک</button>
                        </form>
                    </div>
                </div>
                <div className={styles.image}>
                    <div>
                        <img src={logo} alt="logo" />
                    </div>
                </div>
            </div>
            <div className={styles.botttomSection} onClick={scrollToTop}>
                <Link to="/download">دانلود اپلیکیشن</Link>
            </div>

            {
                invest &&
                <div className={styles.invest_popup}>
                    <div onClick={() => setInvest(false)}><img src={close} alt="close-btn" /></div>
                    <div className={styles.text_field}>
                        <p style={{marginRight : "0"}}>جهت سرمایه گذاری در اپلیکیشن سرویس مدرسه سِیرو با های‌لپ در ارتباط باشید</p>
                        <p style={{fontFamily : "arial"}}>investment@hilap.net</p>
                        <p style={{fontFamily : "arial"}}>09224049874</p>
                        <Link to="/contact-us">سرمایه گذاری</Link>
                    </div>
                </div>
            }
            <ToastContainer />
        </div>
    );
}
 
export default UserRegister;
