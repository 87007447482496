import React, { useState } from "react";

import styles from "./Contact.module.css";
// import mapPng from "../images/map.png"
import axios from "axios";

import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

import { MapContainer, TileLayer , Marker } from 'react-leaflet'
import 'leaflet/dist/leaflet.css'

import L from 'leaflet';

import icon from 'leaflet/dist/images/marker-icon.png';
import iconShadow from 'leaflet/dist/images/marker-shadow.png';

let DefaultIcon = L.icon({
    iconUrl: icon,
    shadowUrl: iconShadow,
    iconSize: [28, 46],
    iconAnchor: [17, 46]
});

L.Marker.prototype.options.icon = DefaultIcon;


const Contact = () => {
  const [data, setData] = useState({
    user_info: "",
    email: "",
    title: "",
    message: "",
    phone_number : ""
  });
  
  const Tnotify = () => toast.success("پیام شما ارسال شد" , {
    rtl: true,
    theme: "colored",
  });
  
const Enotify = () => toast.error("لطفا مجددا امتحان کنید" , {
  rtl: true,
  theme: "colored",
});

  const changeHandler = (e) => {
    setData({
      ...data,
      [e.target.name]: e.target.value,
    });
  };

  const sendHandler = (e) => {
    e.preventDefault();
    axios.post(`${process.env.REACT_APP_BASE_API_URL}service/contact/` , data)
      .then(res => {
        if (res.status === 201) {
          setData({
            user_info: "",
            email: "",
            title: "",
            message: "",
            phone_number : ""
          })
          Tnotify();
        }else{
          Enotify();
        }
      })
      .catch(err => {
        console.log(err);
        Enotify();
      })
  };

  // eslint-disable-next-line
  const [position , setPosition] = useState([35.67359, 51.26606]);

  return (
    <div className={styles.contact_container}>
      <div className={styles.section}>
        <div className={styles.info_section}>
          <div className={styles.info}>
            <h3>دفتر مرکزی</h3>
            <p>
              {" "}
              آدرس : تهران ، بزرگراه فتح ، ابتدای خیابان خلیج فارس ، مجتمع اداری
              کاکتوس ، طبقه 2 ، واحد 4
            </p>
            <p>کد پستی : 1378613481</p>
            <h3>تماس با ما</h3>
            <p>تلفن تماس : 66275993</p>
            <p>پست الکترونیکی : info@seyro.ir</p>
          </div>
          <div className={styles.map}>
            {/* <ContactMap /> */}
            {/* <img src={mapPng} alt="map" /> */}
            <MapContainer center={position} zoom={16} scrollWheelZoom={false} style={{borderRadius : "5px"}}>
              <TileLayer
              attribution='&copy; <a href="https://www.openstreetmap.org/copyright">OpenStreetMap</a> contributors'
              url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
              />
              <Marker position={position}>
              </Marker>
            </MapContainer>
          </div>
        </div>
        <div className={styles.form_section}>
          <h2>ثبت درخواست پشتیبانی</h2>
          <form className={styles.form} onSubmit={sendHandler}>
            <div>
              <input
                type="text"
                placeholder="نام و نام خانوادگی  "
                value={data.user_info}
                name="user_info"
                onChange={changeHandler}
              />
              <input
                type="number"
                placeholder="شماره تماس "
                value={data.phone_number}
                name="phone_number"
                onChange={changeHandler}
              />
            </div>
            <div>
              <input
                type="email"
                placeholder="ایمیل شما "
                value={data.email}
                name="email"
                onChange={changeHandler}
              />
              <input
                type="text"
                placeholder="عنوان "
                value={data.title}
                name="title"
                onChange={changeHandler}
              />
            </div>
            <textarea
              placeholder="توضیحات "
              value={data.message}
              name="message"
              onChange={changeHandler}
            ></textarea>
            <button type="submit">ارسال پیام</button>
          </form>
        </div>
      </div>
      <ToastContainer />
    </div>
  );
};

export default Contact;
