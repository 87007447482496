import React from "react";
// import { useRef } from "react";

// import Banner from "./Home/Banner";
import LearnSection from "./Home/LearnSection";
import InfoSection from "./Home/InfoSection";
import CardsSection from "./Home/CardsSection";
// import DownloadSection from "./Home/DownloadSection";
import UserRegister from "./register/UserRegister";

const HomePage = () => {

  // const resultRef = useRef(null);

  return (
    <>
      {/* <Banner resultRef={resultRef}/> */}
      <UserRegister/>
      <CardsSection />
      <LearnSection />
      <InfoSection />
      {/* <DownloadSection ref={resultRef}/> */}
    </>
  );
};

export default HomePage;
